import React from 'react';

import {TIconProps} from './type';

export const SendIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill={fill as string} {...props}>
      <path d="M1.21122 0.0830791L15.4661 6.90134C15.61 6.97022 15.7315 7.07839 15.8165 7.21336C15.9016 7.34834 15.9467 7.50462 15.9467 7.66415C15.9467 7.82368 15.9016 7.97996 15.8165 8.11493C15.7315 8.24991 15.61 8.35808 15.4661 8.42696L1.21122 15.2452C1.06503 15.3153 0.901773 15.3419 0.740902 15.3218C0.580032 15.3017 0.428326 15.2358 0.303852 15.1319C0.179379 15.028 0.0873844 14.8906 0.038826 14.7359C-0.00973235 14.5812 -0.0128081 14.4159 0.0299652 14.2595L1.45613 9.03112C1.47359 8.96704 1.50952 8.90952 1.55945 8.86573C1.60938 8.82193 1.67109 8.79381 1.7369 8.78485L8.64446 7.84648C8.67327 7.84244 8.70054 7.83104 8.72365 7.81338C8.74676 7.79572 8.76492 7.7724 8.77639 7.74567L8.78857 7.70305C8.79391 7.66528 8.78632 7.62681 8.76703 7.5939C8.74775 7.56098 8.71791 7.53555 8.68235 7.52174L8.64514 7.51159L1.74434 6.57389C1.67866 6.56481 1.61709 6.53663 1.56729 6.49285C1.51749 6.44906 1.48166 6.39161 1.46425 6.32763L0.0299652 1.06881C-0.0128081 0.912436 -0.00973235 0.747062 0.038826 0.592385C0.0873844 0.437709 0.179379 0.300249 0.303852 0.196379C0.428326 0.092508 0.580032 0.0266044 0.740902 0.0065172C0.901773 -0.01357 1.06503 0.0130057 1.21122 0.0830791Z" />
    </svg>
  );
};
