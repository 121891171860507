import React, {useState} from 'react';
// @ts-ignore
import {Media} from 'react-breakpoints';
import {Routes} from '@app/routes';
import {useData} from './useData';

import {FormProfile, FormVehicle, FormSettings, FileUploader} from '@app/component/FormsUser';

//style
import {
  ProfileContainer,
  NavContainer,
  Tab,
  FormWrap,
  Title,
  // Description,
  AvatarWrap,
  Avatar,
  ButtonHelp,
} from './ProfileUserPage.style';
import {LinkStyle} from '@app/component/FormPartnerInfo/FormPartnerInfo.style';

export const ProfileUserPage = () => {
  const [tab, setTab] = useState<'info' | 'vehicle' | 'settings'>('info');

  const isInfo = tab === 'info';
  const isVehicle = tab === 'vehicle';
  const isSettings = tab === 'settings';

  const {
    avatar,
    control,
    errors,
    contactsFields,
    garageFields,
    appendGarageField,
    removeGarageField,
    handleChange,
    onLogout,
    handleUploadAvatar,
    isOpenChangeTheme,
    setIsOpenChangeTheme,
    themeType,
    handleChangeTheme,
    // openLinkSubscribe,
    // register,
    handleSubmit,
  } = useData();
  return (
    <ProfileContainer>
      <LinkStyle to={Routes.FEEDBACK}>
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="white">
          <path d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2M12.19,5.5C11.3,5.5 10.59,5.68 10.05,6.04C9.5,6.4 9.22,7 9.27,7.69H11.24C11.24,7.41 11.34,7.2 11.5,7.06C11.7,6.92 11.92,6.85 12.19,6.85C12.5,6.85 12.77,6.93 12.95,7.11C13.13,7.28 13.22,7.5 13.22,7.8C13.22,8.08 13.14,8.33 13,8.54C12.83,8.76 12.62,8.94 12.36,9.08C11.84,9.4 11.5,9.68 11.29,9.92C11.1,10.16 11,10.5 11,11H13C13,10.72 13.05,10.5 13.14,10.32C13.23,10.15 13.4,10 13.66,9.85C14.12,9.64 14.5,9.36 14.79,9C15.08,8.63 15.23,8.24 15.23,7.8C15.23,7.1 14.96,6.54 14.42,6.12C13.88,5.71 13.13,5.5 12.19,5.5M11,12V14H13V12H11Z" />
        </svg>
      </LinkStyle>
      <Media>
        {({breakpoints, currentBreakpoint}: any) =>
          breakpoints[currentBreakpoint] < breakpoints.sm ? null : (
            <NavContainer>
              <Tab tabActive={!!isInfo} onClick={() => setTab('info')}>
                Личная информация
              </Tab>
              <Tab tabActive={!!isVehicle} onClick={() => setTab('vehicle')}>
                Мои ТС
              </Tab>
              <Tab tabActive={!!isSettings} onClick={() => setTab('settings')}>
                Настройки
              </Tab>
            </NavContainer>
          )
        }
      </Media>
      <Media>
        {({breakpoints, currentBreakpoint}: any) =>
          (breakpoints[currentBreakpoint] < breakpoints.sm || isInfo) && (
            <FormWrap>
              <AvatarWrap>
                <Avatar src={avatar?.original || '/assets/images/web/avatar.png'} alt="avatar" />
                <FileUploader onFileSelectSuccess={(file: File) => handleUploadAvatar(file)} />
              </AvatarWrap>
              <a href="https://qiwi.com/p/79295860447" target={'_blank'} rel="noreferrer">
                <ButtonHelp>
                  Помощь проекту
                  <svg
                    style={{marginLeft: '9px', width: '6px', height: '10px'}}
                    width="8"
                    height="10"
                    viewBox="0 0 8 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1 1L6 5L1 9"
                      stroke="#20D38F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                    />
                  </svg>
                </ButtonHelp>
              </a>
              <Title mb="10px">Личная информация</Title>
              <FormProfile
                control={control}
                errors={errors}
                contactsFields={contactsFields}
                handleChange={handleChange}
              />
            </FormWrap>
          )
        }
      </Media>
      <Media>
        {({breakpoints, currentBreakpoint}: any) =>
          (breakpoints[currentBreakpoint] < breakpoints.sm || isVehicle) && (
            <FormWrap>
              <Title mb="10px">Мои ТС</Title>
              <FormVehicle
                control={control}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                garageFields={garageFields}
                appendGarageField={appendGarageField}
                removeGarageField={removeGarageField}
                errors={errors}
              />
            </FormWrap>
          )
        }
      </Media>
      <Media>
        {({breakpoints, currentBreakpoint}: any) =>
          (breakpoints[currentBreakpoint] < breakpoints.sm || isSettings) && (
            <FormWrap>
              <Title mb="60px">Настройки</Title>
              <FormSettings
                control={control}
                handleChange={handleChange}
                isOpenChangeTheme={isOpenChangeTheme}
                setIsOpenChangeTheme={setIsOpenChangeTheme}
                handleChangeTheme={handleChangeTheme}
                themeType={themeType}
                onLogout={onLogout}
              />
            </FormWrap>
          )
        }
      </Media>
    </ProfileContainer>
  );
};
