import React from 'react';

import {TIconProps} from './type';

export const UserIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 13 13" fill={fill as string} {...props}>
      <path d="M1.08333 13C1.08333 13 0 13 0 11.9167C0 10.8333 1.08333 7.58333 6.5 7.58333C11.9167 7.58333 13 10.8333 13 11.9167C13 13 11.9167 13 11.9167 13H1.08333ZM6.5 6.5C7.36195 6.5 8.1886 6.15759 8.7981 5.5481C9.40759 4.9386 9.75 4.11195 9.75 3.25C9.75 2.38805 9.40759 1.5614 8.7981 0.951903C8.1886 0.34241 7.36195 0 6.5 0C5.63805 0 4.8114 0.34241 4.2019 0.951903C3.59241 1.5614 3.25 2.38805 3.25 3.25C3.25 4.11195 3.59241 4.9386 4.2019 5.5481C4.8114 6.15759 5.63805 6.5 6.5 6.5Z" />
    </svg>
  );
};
