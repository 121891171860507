import React from 'react';

import {TIconProps} from './type';

export const FbIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg
      width={(size * 10) / 19}
      height={size}
      viewBox="0 0 10 19"
      fill={fill as string}
      {...props}>
      <path d="M9.59028 0.0292259L7.2 0.0253906C4.5146 0.0253906 2.77917 1.8059 2.77917 4.5617V6.65325H0.375849C0.168173 6.65325 0 6.82161 0 7.02929V10.0597C0 10.2674 0.168365 10.4356 0.375849 10.4356H2.77917V18.0822C2.77917 18.2899 2.94735 18.4581 3.15502 18.4581H6.29068C6.49835 18.4581 6.66652 18.2897 6.66652 18.0822V10.4356H9.47657C9.68425 10.4356 9.85242 10.2674 9.85242 10.0597L9.85357 7.02929C9.85357 6.92958 9.81388 6.83408 9.7435 6.76351C9.67312 6.69294 9.57724 6.65325 9.47753 6.65325H6.66652V4.88022C6.66652 4.02803 6.8696 3.59542 7.97969 3.59542L9.5899 3.59484C9.79738 3.59484 9.96556 3.42647 9.96556 3.21899V0.405079C9.96556 0.197784 9.79758 0.0296094 9.59028 0.0292259Z" />
    </svg>
  );
};
