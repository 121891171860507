import {Auth} from '@app/bus/auth';
import {object, string, SchemaOf} from 'yup';

export const CODE_COUNT_FIELD = 4;

export const schema: SchemaOf<Auth.PayloadValidateCode> = object().shape({
  code: string().required('required').min(CODE_COUNT_FIELD, 'min_length'),
  login: string().required('required'),
  id: string(),
});
