import React from 'react';

import {TIconProps} from './type';

export const AtvIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 16" fill={fill as string} {...props}>
      <path d="M19.0054 6.56105C19.9738 6.56105 20.8696 6.85158 21.6443 7.33579L21.717 5.49579C21.717 5.27789 21.6201 5.08421 21.4264 4.98737L17.7464 3.02632L16.1243 0.169474C16.0517 0.0484212 15.9306 0 15.8096 0H13.7517C13.6549 0 13.5822 0.0726318 13.5822 0.169474V1.06526C13.5822 1.16211 13.6549 1.23474 13.7517 1.23474H15.277L16.2696 2.97789C15.8822 3.12316 15.4222 3.38947 14.938 3.87368C14.1875 4.6 12.2264 5.76211 9.51486 4.69684C6.56117 3.51053 2.7117 3.05053 1.59801 2.92947C1.42854 2.90526 1.30749 3.05053 1.3317 3.22L1.50117 4.40632C1.52538 4.47895 1.54959 4.55158 1.62222 4.6L3.24433 5.44737L2.97801 6.6821C3.31696 6.60947 3.65591 6.58526 3.99486 6.58526C6.73065 6.58526 8.9338 8.78842 8.9338 11.5242H14.0664C14.0664 8.78842 16.2696 6.56105 19.0054 6.56105Z" />
      <path d="M19.1748 3.17158H19.4653C19.6106 3.17158 19.7316 3.05053 19.7316 2.90526V1.11368C19.7316 0.968421 19.6106 0.847368 19.4653 0.847368H19.1263C18.4485 0.847368 17.9158 1.42842 17.9642 2.13053C18.0127 2.73579 18.5695 3.17158 19.1748 3.17158Z" />
      <path d="M3.99474 7.50526C1.79158 7.50526 0 9.29684 0 11.5C0 13.7032 1.79158 15.4947 3.99474 15.4947C6.19789 15.4947 7.98947 13.7032 7.98947 11.5C7.98947 9.29684 6.19789 7.50526 3.99474 7.50526ZM3.99474 12.9768C3.19579 12.9768 2.51789 12.3232 2.51789 11.5C2.51789 10.7011 3.17158 10.0232 3.99474 10.0232C4.81789 10.0232 5.47158 10.6768 5.47158 11.5C5.47158 12.3232 4.81789 12.9768 3.99474 12.9768Z" />
      <path d="M19.0052 7.50526C16.8021 7.50526 15.0105 9.29684 15.0105 11.5C15.0105 13.7032 16.8021 15.4947 19.0052 15.4947C21.2084 15.4947 23 13.7032 23 11.5C23 9.29684 21.2084 7.50526 19.0052 7.50526ZM19.0052 12.9768C18.2063 12.9768 17.5284 12.3232 17.5284 11.5C17.5284 10.7011 18.1821 10.0232 19.0052 10.0232C19.8284 10.0232 20.4821 10.6768 20.4821 11.5C20.4821 12.3232 19.8042 12.9768 19.0052 12.9768Z" />
    </svg>
  );
};
