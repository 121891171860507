import React from 'react';

import {TIconProps} from './type';

export const VkIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 13" fill={fill as string} {...props}>
      <path d="M19.0411 0.902134C19.1857 0.393237 19.0411 0.0231313 18.4009 0.0231313H16.2738C15.7368 0.0231313 15.489 0.346975 15.3444 0.69395C15.3444 0.69395 14.2499 3.6548 12.7216 5.57473C12.226 6.12989 11.9988 6.31495 11.7303 6.31495C11.5858 6.31495 11.3999 6.12989 11.3999 5.621V0.879003C11.3999 0.277579 11.2347 0 10.7803 0H7.43471C7.10428 0 6.89776 0.277579 6.89776 0.555159C6.89776 1.13345 7.66189 1.27224 7.74449 2.89146V6.40747C7.74449 7.17082 7.62058 7.30961 7.35211 7.30961C6.62929 7.30961 4.87387 4.32562 3.82062 0.925267C3.6141 0.254448 3.40758 0 2.87063 0H0.722819C0.10326 0 0 0.323842 0 0.670817C0 1.29537 0.72282 4.44128 3.36627 8.60498C5.12169 11.4502 7.62058 12.9769 9.87165 12.9769C11.2347 12.9769 11.3999 12.6299 11.3999 12.0516V9.90036C11.3999 9.20641 11.5238 9.09075 11.9575 9.09075C12.2673 9.09075 12.8249 9.2758 14.0847 10.6406C15.5303 12.2598 15.7781 13 16.5835 13H18.7107C19.3303 13 19.6194 12.653 19.4542 11.9822C19.2683 11.3114 18.5661 10.3399 17.6574 9.18327C17.1618 8.53559 16.4183 7.81851 16.1912 7.47153C15.8814 7.0089 15.964 6.82384 16.1912 6.40747C16.1705 6.40747 18.7727 2.29003 19.0411 0.902134Z" />
    </svg>
  );
};
