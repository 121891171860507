import React from 'react';

import {TIconProps} from './type';

export const PhotoIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg
      width={(size * 14) / 13}
      height={size}
      viewBox="0 0 14 13"
      fill={fill as string}
      {...props}>
      <path d="M8.484 2.19203L9.76499 3.47298H12.6V11.1587H1.4V3.47298H4.235L5.516 2.19203H8.484ZM9.09999 0.911072H4.9L3.619 2.19203H1.4C0.63 2.19203 0 2.76846 0 3.47298V11.1587C0 11.8632 0.63 12.4397 1.4 12.4397H12.6C13.37 12.4397 14 11.8632 14 11.1587V3.47298C14 2.76846 13.37 2.19203 12.6 2.19203H10.381L9.09999 0.911072ZM7 5.39441C8.155 5.39441 9.09999 6.25906 9.09999 7.31585C9.09999 8.37263 8.155 9.23728 7 9.23728C5.845 9.23728 4.9 8.37263 4.9 7.31585C4.9 6.25906 5.845 5.39441 7 5.39441ZM7 4.11346C5.068 4.11346 3.5 5.54813 3.5 7.31585C3.5 9.08356 5.068 10.5182 7 10.5182C8.932 10.5182 10.5 9.08356 10.5 7.31585C10.5 5.54813 8.932 4.11346 7 4.11346Z" />
    </svg>
  );
};
