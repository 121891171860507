import React from 'react';

import {TIconProps} from './type';

export const DateIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill={fill as string} {...props}>
      <path d="M4.25 0.53125C4.25 0.390354 4.19403 0.255228 4.0944 0.1556C3.99477 0.0559708 3.85965 0 3.71875 0C3.57785 0 3.44273 0.0559708 3.3431 0.1556C3.24347 0.255228 3.1875 0.390354 3.1875 0.53125V1.0625H2.125C1.56141 1.0625 1.02091 1.28638 0.622398 1.6849C0.223883 2.08341 0 2.62391 0 3.1875L0 4.25H17V3.1875C17 2.62391 16.7761 2.08341 16.3776 1.6849C15.9791 1.28638 15.4386 1.0625 14.875 1.0625H13.8125V0.53125C13.8125 0.390354 13.7565 0.255228 13.6569 0.1556C13.5573 0.0559708 13.4221 0 13.2812 0C13.1404 0 13.0052 0.0559708 12.9056 0.1556C12.806 0.255228 12.75 0.390354 12.75 0.53125V1.0625H4.25V0.53125ZM17 14.875V5.3125H0V14.875C0 15.4386 0.223883 15.9791 0.622398 16.3776C1.02091 16.7761 1.56141 17 2.125 17H14.875C15.4386 17 15.9791 16.7761 16.3776 16.3776C16.7761 15.9791 17 15.4386 17 14.875ZM11.5324 9.40738L8.34488 12.5949C8.29553 12.6443 8.2369 12.6836 8.17236 12.7104C8.10782 12.7372 8.03863 12.7509 7.96875 12.7509C7.89887 12.7509 7.82968 12.7372 7.76514 12.7104C7.7006 12.6836 7.64197 12.6443 7.59262 12.5949L5.99887 11.0011C5.89912 10.9014 5.84308 10.7661 5.84308 10.625C5.84308 10.4839 5.89912 10.3486 5.99887 10.2489C6.09863 10.1491 6.23393 10.0931 6.375 10.0931C6.51607 10.0931 6.65137 10.1491 6.75113 10.2489L7.96875 11.4676L10.7801 8.65512C10.8799 8.55537 11.0152 8.49933 11.1562 8.49933C11.2973 8.49933 11.4326 8.55537 11.5324 8.65512C11.6321 8.75488 11.6882 8.89018 11.6882 9.03125C11.6882 9.17232 11.6321 9.30762 11.5324 9.40738Z" />
    </svg>
  );
};
