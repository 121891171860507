import React from 'react';

import {TIconProps} from './type';

export const ImageIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 39 39" {...props}>
      <path
        d="M1 8.4C1 6.4374 1.77964 4.55518 3.16741 3.16741C4.55518 1.77964 6.4374 1 8.4 1H30.6C32.5626 1 34.4448 1.77964 35.8326 3.16741C37.2204 4.55518 38 6.4374 38 8.4V30.6C38 32.5626 37.2204 34.4448 35.8326 35.8326C34.4448 37.2204 32.5626 38 30.6 38H8.4C6.4374 38 4.55518 37.2204 3.16741 35.8326C1.77964 34.4448 1 32.5626 1 30.6V8.4Z"
        stroke={fill as string}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0256 17.6498C15.58 17.6498 17.6506 15.5791 17.6506 13.0248C17.6506 10.4705 15.58 8.39978 13.0256 8.39978C10.4713 8.39978 8.40063 10.4705 8.40063 13.0248C8.40063 15.5791 10.4713 17.6498 13.0256 17.6498Z"
        stroke={fill as string}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.1737 20.6484L8.40063 37.9996H31.1001C32.9303 37.9996 34.6854 37.2726 35.9795 35.9785C37.2736 34.6844 38.0006 32.9292 38.0006 31.0991C38.0006 30.237 37.6769 29.4063 37.0941 28.7681L29.6386 20.6373C29.2912 20.2583 28.8685 19.9559 28.3976 19.7493C27.9267 19.5427 27.4179 19.4366 26.9037 19.4376C26.3895 19.4387 25.8812 19.5469 25.4111 19.7554C24.9411 19.9638 24.5197 20.268 24.1737 20.6484V20.6484Z"
        stroke={fill as string}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
