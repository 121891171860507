import React from 'react';

import {TIconProps} from './type';

export const TelegramIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 26 26" fill={fill as string} {...props}>
      <path d="M13 26C20.1814 26 26 20.1814 26 13C26 5.81858 20.1814 0 13 0C5.81858 0 0 5.81858 0 13C0 20.1814 5.81858 26 13 26ZM5.94858 12.7183L18.4828 7.88558C19.0645 7.67542 19.5726 8.0275 19.3841 8.90717L19.3852 8.90608L17.251 18.9605C17.0928 19.6733 16.6693 19.8467 16.0767 19.5108L12.8267 17.1156L11.2591 18.6258C11.0857 18.7991 10.9395 18.9453 10.6037 18.9453L10.8344 15.6379L16.8577 10.1963C17.1199 9.96558 16.7992 9.83558 16.4537 10.0653L9.01008 14.7517L5.80125 13.7508C5.10467 13.5297 5.0895 13.0542 5.94858 12.7183Z" />
    </svg>
  );
};
