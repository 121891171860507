import React from 'react';

import {TIconProps} from './type';

export const PartnerIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={fill as string} {...props}>
      <path d="M19.306 8.21451H17.7708C17.5815 7.52997 17.3291 6.88959 17.0137 6.29338L18.1073 5.14511C18.3807 4.85804 18.3807 4.4164 18.1073 4.12934L16.0252 1.94322C15.7518 1.65615 15.3312 1.65615 15.0578 1.94322L13.9642 3.09148C13.3964 2.76025 12.7865 2.49527 12.1346 2.29653V0.706625C12.1346 0.309148 11.8402 0 11.4616 0H8.51735C8.1388 0 7.84437 0.309148 7.84437 0.706625V2.31861C7.19243 2.51735 6.58254 2.78233 6.01472 3.11356L4.92114 1.9653C4.64774 1.67823 4.22713 1.67823 3.95373 1.9653L1.87171 4.15142C1.59832 4.43849 1.59832 4.88013 1.87171 5.16719L2.9653 6.31546C2.64984 6.91167 2.39748 7.55205 2.2082 8.23659H0.672976C0.294427 8.23659 0 8.54574 0 8.94322V12.0347C0 12.4322 0.294427 12.7413 0.672976 12.7413H2.2082C2.39748 13.4259 2.64984 14.0662 2.9653 14.6625L1.87171 15.8107C1.59832 16.0978 1.59832 16.5394 1.87171 16.8265L3.95373 19.0126C4.22713 19.2997 4.64774 19.2997 4.92114 19.0126L6.01472 17.8644C6.58254 18.1956 7.19243 18.4606 7.84437 18.6593C7.99159 18.7035 8.15983 18.7035 8.30704 18.7476V14.5079C6.79285 13.8013 5.72029 12.2114 5.72029 10.3344C5.72029 8.78864 6.45636 7.41956 7.59201 6.58044C7.88644 6.35962 8.28602 6.60252 8.28602 6.97792V9.2082C8.28602 10.1798 9.04311 10.9968 9.98948 10.9968C10.9359 10.9968 11.693 10.2019 11.693 9.2082V6.97792C11.693 6.60252 12.0925 6.35962 12.387 6.58044C13.5016 7.39748 14.2587 8.76656 14.2587 10.3344C14.2587 12.2114 13.1861 13.8233 11.6719 14.5079V18.7256C11.8191 18.7035 11.9874 18.6814 12.1346 18.6372H12.1556C12.8076 18.4606 13.4175 18.1735 13.9853 17.8423L15.0789 18.9905C15.3523 19.2776 15.7729 19.2776 16.0463 18.9905L18.1283 16.8044C18.4017 16.5173 18.4017 16.0757 18.1283 15.7886L17.0347 14.6404C17.3502 14.0442 17.6025 13.4038 17.7918 12.7192H19.327C19.7056 12.7192 20 12.4101 20 12.0126V8.92114C19.979 8.52366 19.6845 8.21451 19.306 8.21451Z" />
    </svg>
  );
};
