import React from 'react';

import {TIconProps} from './type';

export const SosIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={fill as string} {...props}>
      <path d="M20 10C20 4.4771 15.5229 0 10 0C4.4771 0 0 4.4771 0 10C0 15.5229 4.4771 20 10 20C15.5229 20 20 15.5229 20 10ZM9.61811 2.445C10.4245 2.24368 11.2316 2.63309 11.5779 3.39254C11.7006 3.66157 11.7378 3.94565 11.72 4.23953C11.6694 5.07923 11.6203 5.91893 11.572 6.75862C11.4965 8.0705 11.4211 9.3826 11.3479 10.6945C11.3251 11.1012 11.3087 11.5084 11.2911 11.9154C11.2606 12.6265 10.73 13.1513 10.0388 13.1529C9.36344 13.1545 8.82265 12.6363 8.78685 11.9594C8.67924 9.93183 8.57026 7.90452 8.45968 5.8772C8.43004 5.33253 8.39356 4.78831 8.35822 4.24386C8.30328 3.39322 8.82857 2.64198 9.61811 2.445ZM10.0312 17.6884C9.10285 17.6804 8.35138 16.9232 8.3548 15.9992C8.35822 15.0617 9.11448 14.3139 10.0561 14.3173C10.9824 14.3205 11.7348 15.094 11.7257 16.0338C11.7168 16.9355 10.9377 17.6961 10.0312 17.6884Z" />
    </svg>
  );
};
