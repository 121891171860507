import React from 'react';

import {TIconProps} from './type';

export const TimeIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 19" fill={fill as string} {...props}>
      <path d="M9.5 0C4.25353 0 0 4.25353 0 9.5C0 14.7465 4.25353 19 9.5 19C14.7465 19 19 14.7465 19 9.5C19 4.25353 14.7465 0 9.5 0ZM13.8846 10.9615H9.5C9.30619 10.9615 9.12031 10.8845 8.98327 10.7475C8.84622 10.6105 8.76923 10.4246 8.76923 10.2308V3.65385C8.76923 3.46003 8.84622 3.27416 8.98327 3.13711C9.12031 3.00007 9.30619 2.92308 9.5 2.92308C9.69381 2.92308 9.87969 3.00007 10.0167 3.13711C10.1538 3.27416 10.2308 3.46003 10.2308 3.65385V9.5H13.8846C14.0784 9.5 14.2643 9.57699 14.4013 9.71404C14.5384 9.85108 14.6154 10.037 14.6154 10.2308C14.6154 10.4246 14.5384 10.6105 14.4013 10.7475C14.2643 10.8845 14.0784 10.9615 13.8846 10.9615Z" />
    </svg>
  );
};
