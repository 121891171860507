import React from 'react';

import {TIconProps} from './type';

export const PlaceIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg
      width={(size * 11) / 13}
      height={size}
      viewBox="0 0 11 13"
      fill={fill as string}
      {...props}>
      <path d="M5.35024 0C8.30521 0 10.7005 2.39526 10.7005 5.35023C10.7005 7.61105 9.11186 10.0749 5.9758 12.7681C5.80142 12.9179 5.57909 13.0002 5.34921 13C5.11932 12.9998 4.89714 12.9171 4.72302 12.767L4.5156 12.587C1.51837 9.96406 0 7.56057 0 5.35023C0 2.39526 2.39526 0 5.35024 0ZM5.35024 3.29245C4.80448 3.29245 4.28107 3.50925 3.89516 3.89516C3.50925 4.28107 3.29245 4.80448 3.29245 5.35023C3.29245 5.89599 3.50925 6.4194 3.89516 6.80531C4.28107 7.19122 4.80448 7.40802 5.35024 7.40802C5.89599 7.40802 6.4194 7.19122 6.80531 6.80531C7.19122 6.4194 7.40802 5.89599 7.40802 5.35023C7.40802 4.80448 7.19122 4.28107 6.80531 3.89516C6.4194 3.50925 5.89599 3.29245 5.35024 3.29245Z" />
    </svg>
  );
};
