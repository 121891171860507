import React from 'react';

import {TIconProps} from './type';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ChevronRightIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={(size / 8) * 13} height={size} viewBox="0 0 8 13" {...props}>
      <path
        fill="transparent"
        d="M1 1L7 6.5L1 12"
        stroke="#828282"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    // <svg width={(size / 8) * 13} height={size} viewBox="0 0 8 13" {...props}>
    //   <path
    //     d="M1 1L7 6.5L1 12"
    //     stroke={fill as string}
    //     strokeLinecap="round"
    //     strokeLinejoin="round"
    //   />
    // </svg>
  );
};
