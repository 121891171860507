import React from 'react';

import {TIconProps} from './type';

export const PlusCircle2Icon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 42 42" fill={fill as string} {...props}>
      <path d="M21 39.375C16.1266 39.375 11.4529 37.4391 8.00691 33.9931C4.56093 30.5471 2.625 25.8734 2.625 21C2.625 16.1266 4.56093 11.4529 8.00691 8.00691C11.4529 4.56093 16.1266 2.625 21 2.625C25.8734 2.625 30.5471 4.56093 33.9931 8.00691C37.4391 11.4529 39.375 16.1266 39.375 21C39.375 25.8734 37.4391 30.5471 33.9931 33.9931C30.5471 37.4391 25.8734 39.375 21 39.375ZM21 42C26.5695 42 31.911 39.7875 35.8492 35.8492C39.7875 31.911 42 26.5695 42 21C42 15.4305 39.7875 10.089 35.8492 6.15076C31.911 2.21249 26.5695 0 21 0C15.4305 0 10.089 2.21249 6.15076 6.15076C2.21249 10.089 0 15.4305 0 21C0 26.5695 2.21249 31.911 6.15076 35.8492C10.089 39.7875 15.4305 42 21 42Z" />
      <path d="M21 10.5C21.3481 10.5 21.6819 10.6383 21.9281 10.8844C22.1742 11.1306 22.3125 11.4644 22.3125 11.8125V19.6875H30.1875C30.5356 19.6875 30.8694 19.8258 31.1156 20.0719C31.3617 20.3181 31.5 20.6519 31.5 21C31.5 21.3481 31.3617 21.6819 31.1156 21.9281C30.8694 22.1742 30.5356 22.3125 30.1875 22.3125H22.3125V30.1875C22.3125 30.5356 22.1742 30.8694 21.9281 31.1156C21.6819 31.3617 21.3481 31.5 21 31.5C20.6519 31.5 20.3181 31.3617 20.0719 31.1156C19.8258 30.8694 19.6875 30.5356 19.6875 30.1875V22.3125H11.8125C11.4644 22.3125 11.1306 22.1742 10.8844 21.9281C10.6383 21.6819 10.5 21.3481 10.5 21C10.5 20.6519 10.6383 20.3181 10.8844 20.0719C11.1306 19.8258 11.4644 19.6875 11.8125 19.6875H19.6875V11.8125C19.6875 11.4644 19.8258 11.1306 20.0719 10.8844C20.3181 10.6383 20.6519 10.5 21 10.5Z" />
    </svg>
  );
};
