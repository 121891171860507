import React from 'react';

import {TIconProps} from './type';

export const SnowmobileIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 23 12" fill={fill as string} {...props}>
      <path d="M12.0262 9.81609H0.904262C0.71459 9.81609 0.612641 10.0674 0.71459 10.25L1.16506 11.0039C1.18306 11.037 1.20934 11.0649 1.24133 11.0849C1.27333 11.1048 1.30995 11.1161 1.34762 11.1177H11.5804C11.6185 11.1165 11.6556 11.1054 11.6881 11.0855C11.7205 11.0655 11.7472 11.0374 11.7654 11.0039L12.2087 10.25C12.3178 10.0674 12.2087 9.81609 12.0262 9.81609Z" />
      <path d="M22.5578 7.86247C22.4331 7.85397 22.3101 7.89503 22.2155 7.97672C22.121 8.05841 22.0625 8.17413 22.0528 8.29871C21.984 9.33006 20.6942 10.1219 20.1418 10.4136C19.8661 10.5647 19.5576 10.6461 19.2433 10.6506H16.8724L15.924 8.77764H16.7633C16.7633 8.77764 18.7596 8.00235 19.4116 6.16965C19.4542 6.05656 19.4542 5.93184 19.4116 5.81875C19.1745 5.19995 18.0056 3.24158 13.0552 2.39754C13.048 2.30124 13.0116 2.20943 12.9509 2.13437L11.3553 0.216312C11.2892 0.138635 11.2049 0.0786241 11.1098 0.0416952C11.0148 0.00476641 10.9121 -0.00791833 10.8109 0.00478573C10.7098 0.0174898 10.6133 0.0551828 10.5304 0.114463C10.4474 0.173744 10.3806 0.252746 10.3358 0.34434L9.76913 1.49423C9.74087 1.55048 9.7241 1.61179 9.71979 1.6746C9.71547 1.7374 9.72371 1.80043 9.74401 1.86002C9.76432 1.91961 9.79628 1.97455 9.83804 2.02166C9.87981 2.06876 9.93053 2.10708 9.98725 2.13437C10.0999 2.18936 10.2297 2.19753 10.3483 2.1571C10.4669 2.11667 10.5647 2.03093 10.6203 1.91862L10.9617 1.22869L12.1851 2.69627L10.0276 5.10748L3.9462 5.02687C3.9462 5.02687 3.06185 2.82667 1.58952 3.94574C1.47073 4.03627 1.37355 4.15208 1.30501 4.28478C0.649764 5.56072 0.20996 6.93621 0.00338864 8.35562C-0.0037314 8.40768 0.00039825 8.46067 0.015499 8.51101C0.0305997 8.56134 0.0563203 8.60785 0.0909255 8.6474C0.125531 8.68695 0.168216 8.71862 0.216101 8.74026C0.263985 8.76191 0.315956 8.77304 0.368507 8.77289H14.8832C14.8906 8.79892 14.9001 8.8243 14.9116 8.84876L15.8173 10.6459H13.9585C13.8328 10.6459 13.7121 10.6959 13.6232 10.7848C13.5343 10.8737 13.4843 10.9943 13.4843 11.1201C13.4843 11.2458 13.5343 11.3665 13.6232 11.4554C13.7121 11.5443 13.8328 11.5943 13.9585 11.5943H19.2433C19.711 11.593 20.1715 11.4783 20.5852 11.26C21.4458 10.8048 22.9039 9.8232 22.9988 8.36984C23.0033 8.30754 22.9954 8.24496 22.9757 8.1857C22.9559 8.12643 22.9247 8.07165 22.8837 8.02451C22.8427 7.97736 22.7928 7.93878 22.7369 7.91097C22.681 7.88316 22.6201 7.86668 22.5578 7.86247Z" />
    </svg>
  );
};
