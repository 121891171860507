import React, {useState} from 'react';
// @ts-ignore
import {Media} from 'react-breakpoints';
import {Routes} from '@app/routes';

//app
import {FormPartnerInfo} from '@app/component/FormPartnerInfo';
import {FileUploader} from '@app/component/FormsUser/FileUploader';
import {MultiPhotoFiled} from '@app/component/MultiPhotoFiled';
import {FormSettings} from '@app/component/FormsUser/FormSettings';

//local
import {useData} from './useData';

//style
import {
  ProfileContainer,
  LinkStyle,
  Title,
  AvatarWrap,
  FormWrap,
  Avatar,
  ButtonHelp,
  SubscribeInfoLine,
  SubscribeStatusInfo,
} from './ProfilePartnerPage.style';
import {NavContainer, Tab} from '@app/pages/ProfileUserPage/ProfileUserPage.style';

export const ProfilePartnerPage = () => {
  const [tab, setTab] = useState<'info' | 'gallery' | 'settings'>('info');

  const isInfo = tab === 'info';
  const isGallery = tab === 'gallery';
  const isSettings = tab === 'settings';

  const {
    control,
    handleChange,
    handleChangeCategories,
    errors,
    contactsFields,
    avatar,
    photos,
    categories,
    defaultAddress,
    handleChangeAddress,
    handleDeletePhoto,
    handleUploadAvatar,
    handleUploadPhoto,
    isOpenChangeTheme,
    setIsOpenChangeTheme,
    themeType,
    handleChangeTheme,
    onLogout,
    subscribeDate,
    openLinkSubscribe,
  } = useData();

  return (
    <ProfileContainer>
      <LinkStyle to={Routes.FEEDBACK}>
        <svg width="24px" height="24px" viewBox="0 0 24 24" fill="white">
          <path d="M4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2M12.19,5.5C11.3,5.5 10.59,5.68 10.05,6.04C9.5,6.4 9.22,7 9.27,7.69H11.24C11.24,7.41 11.34,7.2 11.5,7.06C11.7,6.92 11.92,6.85 12.19,6.85C12.5,6.85 12.77,6.93 12.95,7.11C13.13,7.28 13.22,7.5 13.22,7.8C13.22,8.08 13.14,8.33 13,8.54C12.83,8.76 12.62,8.94 12.36,9.08C11.84,9.4 11.5,9.68 11.29,9.92C11.1,10.16 11,10.5 11,11H13C13,10.72 13.05,10.5 13.14,10.32C13.23,10.15 13.4,10 13.66,9.85C14.12,9.64 14.5,9.36 14.79,9C15.08,8.63 15.23,8.24 15.23,7.8C15.23,7.1 14.96,6.54 14.42,6.12C13.88,5.71 13.13,5.5 12.19,5.5M11,12V14H13V12H11Z" />
        </svg>
      </LinkStyle>
      <Media>
        {({breakpoints, currentBreakpoint}: any) =>
          breakpoints[currentBreakpoint] < breakpoints.sm ? null : (
            <NavContainer>
              <Tab tabActive={!!isInfo} onClick={() => setTab('info')}>
                Личная информация
              </Tab>
              <Tab tabActive={!!isGallery} onClick={() => setTab('gallery')}>
                Фотографии
              </Tab>
              <Tab tabActive={!!isSettings} onClick={() => setTab('settings')}>
                Настройки
              </Tab>
            </NavContainer>
          )
        }
      </Media>
      <FormWrap>
        <Media>
          {({breakpoints, currentBreakpoint}: any) =>
            (breakpoints[currentBreakpoint] < breakpoints.sm || isInfo) && (
              <>
                <Title>Основное</Title>
                <AvatarWrap>
                  <Avatar src={avatar?.original || '/assets/images/web/avatar.png'} alt="avatar" />
                  <FileUploader onFileSelectSuccess={(file: File) => handleUploadAvatar(file)} />
                </AvatarWrap>
                <SubscribeInfoLine>
                  <ButtonHelp onClick={openLinkSubscribe}>Оплатить доступ</ButtonHelp>
                  <SubscribeStatusInfo>{subscribeDate}</SubscribeStatusInfo>
                </SubscribeInfoLine>
                <FormPartnerInfo
                  control={control}
                  // @ts-ignore: Unreachable code error
                  errors={errors}
                  contactsFields={contactsFields}
                  handleChange={handleChange}
                  defaultAddress={defaultAddress}
                  handleChangeAddress={handleChangeAddress}
                  categories={categories}
                  handleChangeCategories={handleChangeCategories}
                />
              </>
            )
          }
        </Media>
        <Media>
          {({breakpoints, currentBreakpoint}: any) =>
            (breakpoints[currentBreakpoint] < breakpoints.sm || isGallery) && (
              <>
                <Title>Фотографии</Title>
                <MultiPhotoFiled
                  items={photos}
                  onChange={handleUploadPhoto}
                  onDelete={handleDeletePhoto}
                />
              </>
            )
          }
        </Media>
        <Media>
          {({breakpoints, currentBreakpoint}: any) =>
            (breakpoints[currentBreakpoint] < breakpoints.sm || isSettings) && (
              <FormWrap>
                <Title mb="60px">Настройки</Title>
                <FormSettings
                  control={control}
                  handleChange={handleChange}
                  isOpenChangeTheme={isOpenChangeTheme}
                  setIsOpenChangeTheme={setIsOpenChangeTheme}
                  handleChangeTheme={handleChangeTheme}
                  themeType={themeType}
                  onLogout={onLogout}
                />
              </FormWrap>
            )
          }
        </Media>
      </FormWrap>
    </ProfileContainer>
  );
};
