import React from 'react';
// import svg, {path, Circle} from 'react-native-svg';

import {TIconProps} from './type';

export const QuestionCircleIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 24 24" fill={fill as string} {...props}>
      <circle cx="12" cy="12" r="10.85" strokeWidth="2.3" fill="none" stroke={fill as string} />
      <path d="M8.00032 6.84081C7.99827 6.88735 8.00614 6.9338 8.02347 6.9773C8.04079 7.02081 8.06719 7.06045 8.10105 7.09379C8.13491 7.12713 8.17551 7.15346 8.22036 7.17117C8.2652 7.18887 8.31334 7.19758 8.36182 7.19675H9.59932C9.80632 7.19675 9.97132 7.03391 9.99832 6.83648C10.1333 5.89114 10.8083 5.2023 12.0113 5.2023C13.0403 5.2023 13.9823 5.69659 13.9823 6.88548C13.9823 7.80056 13.4213 8.22136 12.5348 8.8612C11.5253 9.56588 10.7258 10.3887 10.7828 11.7246L10.7873 12.0373C10.7889 12.1319 10.8291 12.222 10.8993 12.2884C10.9694 12.3547 11.0639 12.3918 11.1623 12.3918H12.3788C12.4783 12.3918 12.5737 12.3539 12.644 12.2863C12.7143 12.2187 12.7538 12.1271 12.7538 12.0316V11.8802C12.7538 10.8456 13.1633 10.5444 14.2688 9.73881C15.1823 9.07159 16.1348 8.33088 16.1348 6.77596C16.1348 4.59849 14.2208 3.54651 12.1253 3.54651C10.2248 3.54651 8.14282 4.39674 8.00032 6.84081ZM10.3358 15.1457C10.3358 15.9138 10.9733 16.4816 11.8508 16.4816C12.7643 16.4816 13.3928 15.9138 13.3928 15.1457C13.3928 14.3503 12.7628 13.7911 11.8493 13.7911C10.9733 13.7911 10.3358 14.3503 10.3358 15.1457Z" />
    </svg>
  );
};
