import React from 'react';

import {TIconProps} from './type';

export const CloseIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 20 20" fill={fill as string} {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.9998 20.0002C4.48588 20.0002 0 15.5141 0 10C0 4.48607 4.48588 0 9.9998 0C15.5139 0 20 4.48607 20 10C20 15.5141 15.5139 20.0002 9.9998 20.0002ZM9.9998 1.37231C5.2426 1.37231 1.37231 5.2428 1.37231 10C1.37231 14.7574 5.2426 18.6279 9.9998 18.6279C14.7572 18.6279 18.6277 14.7574 18.6277 10C18.6277 5.2428 14.7572 1.37231 9.9998 1.37231Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.37688 13.3092C7.20122 13.3092 7.02557 13.2424 6.89167 13.1083C6.62368 12.8405 6.62368 12.4058 6.89167 12.138L12.1378 6.89191C12.4058 6.62392 12.84 6.62392 13.108 6.89191C13.376 7.15971 13.376 7.59434 13.108 7.86213L7.86189 13.1083C7.72779 13.2424 7.55234 13.3092 7.37688 13.3092Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6228 13.3092C12.4474 13.3092 12.2717 13.2424 12.1378 13.1083L6.89167 7.86213C6.62368 7.59434 6.62368 7.15971 6.89167 6.89191C7.15966 6.62392 7.5939 6.62392 7.86189 6.89191L13.108 12.138C13.376 12.4058 13.376 12.8405 13.108 13.1083C12.9739 13.2424 12.7985 13.3092 12.6228 13.3092Z"
      />
    </svg>
  );
};
