import React from 'react';

import {TIconProps} from './type';

export const ListIcon: React.FC<TIconProps> = ({size, fill, ...props}) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 18" fill={fill as string} {...props}>
      <path d="M0 16.2778C0 17.1996 0.77628 18.0002 1.69811 18.0002C2.61995 18.0002 3.39623 17.2239 3.39623 16.2778C3.39623 15.356 2.61995 14.5554 1.69811 14.5554C0.77628 14.5797 0 15.356 0 16.2778Z" />
      <path d="M0 8.99996C0 9.92179 0.77628 10.7223 1.69811 10.7223C2.61995 10.7223 3.39623 9.94605 3.39623 8.99996C3.39623 8.07813 2.61995 7.27759 1.69811 7.27759C0.77628 7.30185 0 8.07813 0 8.99996Z" />
      <path d="M0 1.72237C0 2.64421 0.77628 3.44474 1.69811 3.44474C2.61995 3.44474 3.39623 2.66846 3.39623 1.72237C3.39623 0.800539 2.61995 0 1.69811 0C0.77628 0.0242588 0 0.800539 0 1.72237Z" />
      <path d="M16.9813 3.44474H6.47725C5.53116 3.44474 4.75488 2.66846 4.75488 1.72237C4.75488 0.77628 5.53116 0 6.47725 0H16.9813C17.9274 0 18.7037 0.77628 18.7037 1.72237C18.7037 2.66846 17.9274 3.44474 16.9813 3.44474Z" />
      <path d="M16.9813 10.7223H6.47725C5.53116 10.7223 4.75488 9.94605 4.75488 8.99996C4.75488 8.05387 5.53116 7.27759 6.47725 7.27759H16.9813C17.9274 7.27759 18.7037 8.05387 18.7037 8.99996C18.7037 9.94605 17.9274 10.7223 16.9813 10.7223Z" />
      <path d="M16.9813 18.0002H6.47725C5.53116 18.0002 4.75488 17.2239 4.75488 16.2778C4.75488 15.3317 5.53116 14.5554 6.47725 14.5554H16.9813C17.9274 14.5554 18.7037 15.3317 18.7037 16.2778C18.7037 17.2239 17.9274 18.0002 16.9813 18.0002Z" />
    </svg>
  );
};
